<template>
  <div class="mygroup-list" style="padding-top: 0">
    <div class="mygroup-title mygroup-title-top">
      <span>拼团单号：{{groupDetail.orderSn}}</span>
      <span class="share-btn" @click="share" v-if="groupDetail.groupType==1 && groupDetail.purchaseStatus==0 && userId ==groupDetail.originatorId">邀请</span>
    </div>
    <div class="mygroup-info">
      <div class="mygroup-img">
        <img :src="groupDetail.goodsFileUrl" />
      </div>
      <div class="mygroup-info-r">
        <h4>{{groupDetail.goodsName}} <span class="private_domain" v-if="groupDetail.groupType==1">私域拼团</span></h4>
        <div class="mygroup-info-list">
          <span v-if="groupDetail.purchaseType==0">{{groupDetail.joinNum}}人团 </span>
          <span v-if="groupDetail.purchaseType==0">共{{groupDetail.totalNum+groupDetail.unit}}</span>
          <span v-if="groupDetail.purchaseType==0"> 每人{{groupDetail.perNum+groupDetail.unit}}</span>
          <span v-if="groupDetail.purchaseType==='1'">{{groupDetail.totalNum+groupDetail.unit}}团</span>
        </div>
        <div class="mygroup-info-list">
          <span class="price-new">¥{{groupDetail.goodsPrice}}/{{groupDetail.unit}}</span>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
  <div class="mygroup-list group-result">
      <div style="width:2.3rem" class="mygroup-info-list group-result-01" v-if="groupDetail.purchaseStatus==0&&groupDetail.purchaseType==1">
      <img src="../../images/g_ing_icon.png" />
      <span class="font20">进行中，还差{{groupDetail.remainWeightCount}}{{groupDetail.unit}}成团</span>
    </div>
    <div class="mygroup-info-list group-result-01" v-if="groupDetail.purchaseStatus==2">
      <img src="../../images/g_fail_icon.png" />
      <span class="font20">拼单失败</span>
    </div>
    <div class="mygroup-info-list group-result-01" v-if="groupDetail.purchaseStatus==1">
      <img src="../../images/g_sucess_icon.png" />
      <span class="font20">拼单成功</span>
    </div>
    <div class="mygroup-info-list group-result-02">
      <span>{{groupDetail.beginTimeStr}}</span>
      <span v-if="groupDetail.purchaseType==0">共{{groupDetail.makeGroupJoinNum}}人成团</span>
    </div>
    <div class="mygroup-info-list group-result-03" v-if="groupDetail.userList">
      <img v-for="item in groupDetail.userList" :src="item" :key="item" />
      <i v-if="groupDetail.userList.length>0"></i>
    </div>
    <div v-if="groupDetail.purchaseStatus==0&&groupDetail.makeGroupEndTime!=''">剩余：<span class="countdown">{{groupDetail.makeGroupEndTime1}}</span></div>
    <div class="mygroup-info-list group-result-04">
      <router-link to="/">再去逛逛</router-link>
    </div>
    <div class="mygroup-info-list group-result-04" @click="goPay()" v-if="isToBuy===true">
      <span>去付款</span>
    </div>
  </div>
  <div class="mygroup-list" v-if="groupDetail.purchaseStatus==2">
    <div class="mygroup-title">
      <img class="l" src="../../images/g_fail_tip.png" />
      <span class="l">失败原因</span>
      <div class="clear"></div>
    </div>
    <div class="group-result-p">团购已经过期未达到成团条件</div>
    <div class="group-result-intro">
      <p>支付的订单金额会在12~24小时内</p>
      <p>原路退回</p>
    </div>
  </div>
  <div class="mygroup-list" v-if="groupDetail.purchaseStatus==1">
      <div class="cell">
          <div class="cell-l">
              <img src="../../images/g_person.png"/>
              <span>收货人</span>
          </div>
          <div class="cell-r">
              <span>{{groupDetail.customerName}}</span>
          </div>
      </div>
      <div class="cell">
          <div class="cell-l">
              <img src="../../images/g_phone.png"/>
              <span>收货人手机</span>
          </div>
          <div class="cell-r">
              <span>{{groupDetail.mobile}}</span>
          </div>
      </div>
      <div class="cell">
          <div class="cell-l">
              <img src="../../images/g_address.png"/>
              <span v-if="groupDetail.selfDeliveryPhone || groupDetail.selfDeliveryName">自提点地址</span>
              <span v-else>收货人地址</span>
          </div>
          <div class="cell-r">
              <span>{{groupDetail.address}}</span>
          </div>
      </div>
      <div class="cell" v-if="groupDetail.selfDeliveryName">
          <div class="cell-l">
              <img src="../../images/groupS.png"/>
              <span>自提点名称</span>
          </div>
          <div class="cell-r">
              <span>{{groupDetail.selfDeliveryName}}</span>
          </div>
      </div>
      <div class="cell" v-if="groupDetail.selfDeliveryPhone">
          <div class="cell-l">
              <img src="../../images/g_phone.png"/>
              <span>自提点电话</span>
          </div>
          <div class="cell-r">
              <span>{{groupDetail.selfDeliveryPhone}}</span>
          </div>
      </div>
      <div class="cell">
          <div class="cell-l">
              <img src="../../images/g_phone.png"/>
              <span>店铺电话</span>
          </div>
          <div class="cell-r">
              <span>{{groupDetail.contactPhone}}</span>
          </div>
      </div>
  </div>
  <div :class="{'mask2': true, 'mask2Show': maskshow===true}" @click="closeMask">
    <img src="../../images/share.png"/>
  </div>
</template>
<script>
import qs from 'qs'
import { reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import wx from 'weixin-js-sdk'
import { post, ajaxParam, formatTime, config } from '../../units/request.js'
export default {
  name: 'GroupDetail',
  setup () {
    const data = reactive({ groupDetail: {}, maskshow: false, userId: '', isToBuy: false })
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    data.userId = localStorage.userId
    // purchaseStatus 0组团中,1组团成功,2组团失败3发起拼团
    const getGroupDetail = async () => {
      const groupId = route.params.groupId
      const param = {
        mguid: groupId
      }
      ajaxParam(param)
      const res = await post('v1/groupPurchase/buyerGetGroupOrderInfoDetail.json', qs.stringify(param))
      const myDate = new Date(res.data.entity.finishedTime)
      res.data.entity.beginTimeStr = formatTime(myDate)
      data.groupDetail = res.data.entity
      console.log(data.groupDetail)
      if (data.groupDetail.purchaseStatus === '0') {
        getCountDown(data.groupDetail.makeGroupEndTime)
      }
      // 增加付款状态的校验
      if (data.groupDetail.joinType === '2' && (data.groupDetail.purchaseStatus === '0' || data.groupDetail.purchaseStatus === '3') && data.groupDetail.joinStatus === '0' && Date.now() <= data.groupDetail.makeGroupEndTime && (data.groupDetail.makeGroupJoinNum + data.groupDetail.buyNum <= data.groupDetail.makeGroupTotalNum)) {
        data.isToBuy = true
      }
    }
    getGroupDetail()
    const getCountDown = (timestamp) => {
      var intervalId = setInterval(function () {
        var t = (timestamp - Date.parse(new Date()))
        if (t <= 0) {
          clearInterval(intervalId)
        }
        var hour = Math.floor(t / 1000 / 60 / 60 % 24)
        var min = Math.floor(t / 1000 / 60 % 60)
        var sec = Math.floor(t / 1000 % 60)
        if (hour < 10) {
          hour = '0' + hour
        }
        if (min < 10) {
          min = '0' + min
        }
        if (sec < 10) {
          sec = '0' + sec
        }
        var countDownTime = hour + ':' + min + ':' + sec
        data.groupDetail.makeGroupEndTime1 = countDownTime
        if (countDownTime === '00:00:00') {
          clearInterval(intervalId)
          // setTimeout(res => {
          //   getGroupDetail()
          // }, 10000)
        }
      }, 1000)
    }
    const share = async () => {
      data.maskshow = true
      const url = window.location.href.split('#')[0]
      const param = {
        url: url,
        appId: config.appId,
        appSecret: config.appSecret
      }
      ajaxParam(param)
      const res = await post('v2/user/getWxShareSignature.json', qs.stringify(param))
      if (wx) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: config.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp + '', // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr + '', // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名，见附录1
          jsApiList: ['onMenuShareAppMessage'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.ready(() => {
          wx.onMenuShareAppMessage({
            title: data.groupDetail.goodsName,
            desc: data.groupDetail.goodsName,
            link: encodeURI(url + '#/detail?id=' + data.groupDetail.id + '&originatorId=' + localStorage.userId),
            imgUrl: '',
            type: '',
            success: (res) => {
              data.maskshow = false
            },
            cancel: (res) => {
              data.maskshow = false
            }
          })
        })
        wx.error(function (res) {
          // alert(res)
        })
      }
      /* router.push({
        path: '/detail',
        query: {
          id: cartList.value.groupId,
          originatorId: localStorage.userId
        }
      }) */
    }
    const closeMask = () => {
      data.maskshow = false
    }
    const goPay = () => {
      const paySn = data.groupDetail.orderSn
      store.commit('changeCar', data.groupDetail)
      router.push({ path: `/paytype/${paySn}` })
    }
    const { groupDetail, maskshow, userId, isToBuy } = toRefs(data)
    return { groupDetail, maskshow, userId, isToBuy, share, closeMask, goPay }
  }
}
</script>
<style lang="scss" scoped>
.mygroup-title-top{
  display: flex;
  justify-content: space-between;
}
.mygroup-list {
  background: #fff;
  margin-bottom: 0.1rem;
  padding: 0.2rem;
}
.mygroup-title {
  display: flex;
  align-items: center;
  height: 0.6rem;
  line-height: 0.6rem;
  border-bottom: 1px solid #f2f2f2;
  font-size: 0.16rem;
}
.mygroup-title img {
  width: 0.24rem;
  height: 0.24rem;
  margin: 0 0.1rem 0 0;
}
.mygroup-info {
  margin: 0.1rem 0;
  position: relative;
}
.mygroup-img {
  float: left;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.15rem;
}
.mygroup-img img {
  width: 100%;
  height: 100%;
}
.mygroup-info-r {
  overflow: hidden;
}
.mygroup-info-r h4 {
  margin-top: 0.05rem;
  margin-bottom: 0;
}
.private_domain{
  padding:0 .03rem;
  background-color: red;
  font-size: .13rem;
  color:white !important;
  font-weight:bold;
  line-height: .2rem;
}
.mygroup-info-list {
  margin-top: 0.1rem;
}
.group-result {
  text-align: center;
}
.group-result-01 {
  width: 1.5rem;
  height: 0.4rem;
  margin: 0.1rem auto;
  line-height: 0.4rem;
}
.group-result-01 img {
  float: left;
  width: 0.24rem;
  height: 0.24rem;
  margin-top: 0.08rem;
}
.group-result-02 span:nth-of-type(2){
    padding-left: .2rem
}
.group-result-03{
    position: relative;
    display: inline-block;
}
.group-result-03 img {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  margin: 0.1rem auto;
}
.group-result-03 i{
    display: block;
    width: 0.29rem;
    height: 0.22rem;
    position: absolute;
    top: -0.05rem;
    left: -0.08rem;
    background: url("../../images/hg_icon.png") no-repeat 0 center;
    background-size: 0.29rem 0.22rem;
}
.group-result-04 a, .group-result-04 span {
  display: inline-block;
  margin-top: .08rem;
  height: 0.24rem;
  padding: 0.02rem .16rem;
  border: 1px solid #2ac560;
  color: #2ac560;
  text-align: center;
  line-height: 0.24rem;
  border-radius: 0.04rem;
  text-decoration: none;
}

.group-result-p {
  margin: 0.3rem 0;
  text-align: center;
}
.group-result-intro {
  margin: 0 0.3rem;
  padding: 0.3rem 0.15rem;
  background: #f0f2f5;
  text-align: center;
  color: #2ac560;
  line-height: 0.26rem;
}
.group-status-01 {
  color: #ed8779;
}
.group-status-02 {
  color: #f78d04;
}
.font20{font-size: .2rem;}
.cell{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: .1rem;
    border-bottom: .01rem solid #f2f2f2;
    .cell-l{
        display: flex;
        align-items: center;
        img{
            width:.24rem;
            height: .25rem;
            margin-right: .1rem
        }
        span {
            color:#999
        }
    }
    .cell-r {
        color:#666
    }
}
.price-new{
  color:#ff371e
}
.countdown{
  background:red;
  padding: 0 .05rem;
  color:#fff
}
.share-btn{
    color: #3b3b3b;
    width:.4rem;
    height: .28rem;
    padding-left: .18rem;
    line-height: .28rem;
    background: url(../../images/icon_share_h.png) no-repeat 0 center;
    background-size: .16rem .16rem;
}
.mask2{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background:rgba(0,0,0,.6);
  z-index: 99;
  display: none;
  }
.mask2 img{
  width:3rem;
  position:absolute;
  left: 50%;
  top: 5%;
  margin-left: -1.5rem
}
.mask2Show{
    display: block;
}
</style>
